var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { getApiUrl } from '@/shared/utils/helpers';
let RDStationCallback = class RDStationCallback extends Vue {
    constructor() {
        super(...arguments);
        this.code = '';
        this.client_id_rd_station = process.env.NODE_ENV === 'development'
            ? 'baea9d54-bc8b-41c7-8218-1f66f582119d'
            : getApiUrl().search('api.dev') >= 0
                ? '64251d97-f979-45f9-be1b-da1cd40f294d'
                : '0b06df84-37d5-4334-b171-cb7a913a2269';
        this.client_secret_rd_station = process.env.NODE_ENV === 'development'
            ? '6b7ef325a40345c1b96a63175167db59'
            : getApiUrl().search('api.dev') >= 0
                ? '930743f490654320a621486642f6e459'
                : 'cceee428625647fbaa26b7e3cf2fceb8';
    }
    async getTokenRDStation() {
        // @ts-ignore
        await this.actions.getTokenRDStation({
            client_id: this.client_id_rd_station,
            client_secret: this.client_secret_rd_station,
            code: this.code,
        });
    }
    mounted() {
        // @ts-ignore
        this.code = decodeURI(this.$route.query.code);
        this.getTokenRDStation();
    }
};
RDStationCallback = __decorate([
    Component(connect(({ state, effects, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'RDStationCallback',
    }))
], RDStationCallback);
export default RDStationCallback;
